

import DialoguePopup from "../../helpers/DialoguePopup";
import React, { useState, useRef, useEffect, Fragment } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import BankService from "../../services/bank.services";
import clientmanagmentObj from "../../services/client-managment.service";
import BoLoader from "../../helpers/BoLoader";
import moment from "moment";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ReactDatePickerHelper from "../../helpers/ReactDatePickerHelper";
import { useSelector } from "react-redux";
import GetAllClients from "../../helpers/GetAllClients";
import RefreshToken from "../../helpers/RefreshToken";
import ReactFlagsSelect from "react-flags-select";
import { TrimCharacters } from "../../helpers/TrimCharacters";

const AddBankAccountsBody = props => {
  const { user: currentUser } = useSelector(state => state.auth);

  const [cardHolderDetailsAreaSelected, setCardHolderDetailsAreaSelected] =
    useState("true");
  const [cardDetailsAreaSelected, setCardDetailsAreaSelected] =
    useState("false");
  const [cardHolderDetailsClassName, setTcardHolderDetailsClassName] =
    useState("nav-link active");
  const [cardDetailsClassName, setTcardDetailsClassName] = useState("nav-link");
  const [cardHolderContentClassName, setCardHolderContentClassName] = useState(
    "tab-pane fade show active"
  );
  const [cardContentClassName, setCardContentClassName] =
    useState("tab-pane fade");
  const [modalShow, setModalShow] = React.useState(false);
  const form = useRef();
  const checkBtn = useRef();
  const [modelhdng, setMdlHdng] = useState("");
  const [MdlIcon, setMdlIcon] = useState("");
  const [modelbdy, setMdlBdy] = useState("");
  const [disableBtns, setDisabledBtns] = useState(false);
  const [boloader, setBoLoader] = useState(false);
  const [ClientNameVal, setClientNameVal] = useState(currentUser?.user?.ClientId);
  const [FirstName, setFirstName] = useState("");
  const [PaymentProcessorClient, setPaymentProcessorClient] = useState("");
  const [cardFeeCurrencyCode, setcardFeeCurrencyCode] = useState();
  const [cardCurrency, setcardCurrency] = useState();
  const [showClientErr, setShowClientErr] = useState("d-none");
  const [errors, setErrors] = useState({}); // Error state

  //Issuer S or ISsuer B For m-Swipe
  const [IssuerCategory, setIssuerCategory] = useState();
  const [CardFlow, setCardFlow] = useState();
  const [CheckcardVisaMaster, setCheckCardVisaMaster] = useState("Select");
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    mailingAddressNo: 0,
    mailingAddressLine1: '',
    mailingCity: '',
    mailingStateCode: '',
    mailingCountryCode: '',
    nationality: '',
    mailingPostalCode: '',
    taxReferenceNumber: 0,
    gender: '',
    dateOfBirth: '',
    usResidencyStatus: '',
    employmentStatus: '',
    employerName: '',
    occupation: '',
    primarySourceOfFunds: '',
    engageInActivities: '',
    vendorsAndCounterparties: [],
    fundsSendReceiveJurisdictions: [],
    totalAssets: '',
    usdValueOfFiat: '',
    monthlyDeposits: '',
    monthlyWithdrawals: '',
    monthlyInvestmentDeposit: '',
    monthlyInvestmentWithdrawal: '',
    passportNumber: '',
    phone: '',
    email: '',
    termsAndConditions: true,
    clientId: '',
  });
  // Custom validation rules
  const validateField = (name, value) => {
    let errorMsg = "";

    // Ensure value is a string before using trim()
    const strValue = value !== undefined && value !== null ? String(value).trim() : "";

    if (!strValue) {
      errorMsg = `${name} is required`;
    } else {
      if (name === "email" && !/^\S+@\S+\.\S+$/.test(strValue)) {
        errorMsg = "Invalid email format";
      }
      if (name === "phone" && !/^\d{10,15}$/.test(strValue)) {
        errorMsg = "Invalid phone number (must be 10-15 digits)";
      }
    }

    return errorMsg;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Validate field
    const errorMsg = validateField(name, value);

    // Update state
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Update errors
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMsg,
    }));
  };
  //API Call Start
  const IssueCardHolderHandler = e => {
    if (e) e.preventDefault();
    form.current.validateAll();

    let checkclient = requiredClient(ClientNameVal);
    formData.clientId = Number(ClientNameVal);
    formData.mailingAddressNo = Number(formData?.mailingAddressNo);
    formData.taxReferenceNumber = Number(formData?.taxReferenceNumber);
    let newErrors = {};
    Object.keys(formData).forEach((field) => {
      const errorMsg = validateField(field, formData[field]);
      if (errorMsg) newErrors[field] = errorMsg;
    });
    // If errors exist, update state and stop submission
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    if (checkclient) {
      setBoLoader(true);
      BankService.createbankaccount
        (formData,
        )
        .then(bankData => {
          setBoLoader(false);
          if (bankData && bankData.data && bankData.data.success) {
            setCardHolderDetailsAreaSelected("false");
            setCardDetailsAreaSelected("true");
            setTcardHolderDetailsClassName("nav-link");
            setTcardDetailsClassName("nav-link active");
            setCardHolderContentClassName("tab-pane fade");
            setCardContentClassName("tab-pane fade show active");
          }
        })
        .catch(err => {
          setBoLoader(false);
          let msgBody =
            err && err.response && err.response.data
              ? err.response.data.message
              : "Error Occured While Creating Account";
          let errIcon = "icon-error";
          errorAndSuccessModl("Error", msgBody, errIcon);
          (async () => {
            setBoLoader(true);
            let resp = await RefreshToken(
              err && err.response && err.response.status
            );
            if (resp) {
              IssueCardHolderHandler();
              setBoLoader(false);
            } else {
              setBoLoader(false);
            }
          })();
        });
    }

  };
  //API Call End
  const errorAndSuccessModl = (heading, body, Icon) => {
    setModalShow(true);
    setMdlHdng(heading);
    setMdlBdy(body);
    setMdlIcon(Icon);
  };

  const IssueCardDoneHandler = e => {
    e.preventDefault();
    // setDisabledBtns(true);
    props.onHide();
  };
  const ClientNameChangeHandler = async e => {
    const clientName = e.target.selectedOptions[0].value;
    await clientmanagmentObj
      .getprofiledetails(String(clientName))
      .then((response) => {
        setPaymentProcessorClient(response?.data?.payload?.result?.paymentProcesor)
      })
    const paymentProcessor =
      e?.target?.selectedOptions[0]?.attributes[1]?.nodeValue ?? "EUR";
    const currency = e?.target?.selectedOptions[0]?.attributes[0]?.nodeValue ?? "-";
    requiredClient(clientName);
    const issuercat = e?.target?.selectedOptions[0]?.attributes[4]?.nodeValue;
    const cardflow = e?.target?.selectedOptions[0]?.attributes[3]?.nodeValue;
    const checkVisaMaster = e?.target?.selectedOptions[0]?.attributes[5]?.nodeValue;

    setClientNameVal(clientName);
    setcardFeeCurrencyCode(currency);
    setcardCurrency(currency);
    setIssuerCategory(issuercat);
    setCardFlow(cardflow);
    setCheckCardVisaMaster(checkVisaMaster);
  };

  const requiredClient = value => {
    if (!value) {
      document.getElementById("id_client").classList.add("has_error");
      setShowClientErr("");
      return false;
    } else {
      document.getElementById("id_client").classList.remove("has_error");
      setShowClientErr("d-none");
      return true;
    }
  };

  return (
    <Fragment>
      <div className="row">
        <BoLoader loading={boloader} />
        <div className="col-md-12">
          <h2>Create Account </h2>
          <div className="mt-16">
            <div className="row">
              <div className="col-md-12">
                <div className="bo-tabs-mbl">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className={cardHolderDetailsClassName} //""
                        id="cardHolder-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#cardHolder"
                        type="button"
                        role="tab"
                        aria-controls="cardHolder"
                        aria-selected={cardHolderDetailsAreaSelected}
                      >
                        AccountHolder Details
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={cardDetailsClassName}
                        id="cardDetails-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#cardDetails"
                        type="button"
                        role="tab"
                        aria-controls="cardDetails"
                        aria-selected={cardDetailsAreaSelected}
                      >
                        Account Details
                      </button>
                    </li>
                  </ul>
                </div>

                <div className="tab-content" id="myTabContent">
                  <div
                    className={cardHolderContentClassName}
                    id="cardHolder"
                    role="tabpanel"
                    aria-labelledby="cardHolder-tab"
                  >
                    <div>
                      <h3>Enter Account details</h3>
                      <Form onSubmit={(e) => { e.preventDefault() }} ref={form}>
                        <div className="row mt-16">
                          
                          <div className="col-md-6">
                            <div className="form-group" id="id_firstName">
                              <label>First Name*</label>
                              <div className="form-controls">
                                <Input
                                  type="text"
                                  className="form-control no-icon"
                                  name="firstName"
                                  value={formData.firstName}
                                  onChange={handleChange}
                                />
                              </div>
                              {errors.firstName && (
                                <span className="error_meesage">{errors.firstName}</span>
                              )}
                            </div>
                          </div>

                          {/* Last Name */}
                          <div className="col-md-6">
                            <div className="form-group" id="id_lastName">
                              <label>Last Name*</label>
                              <div className="form-controls">
                                <Input
                                  type="text"
                                  className="form-control no-icon"
                                  name="lastName"
                                  value={formData.lastName}
                                  onChange={handleChange}
                                />
                              </div>
                              {errors.lastName && (
                                <span className="error_meesage">{errors.lastName}</span>
                              )}
                            </div>
                          </div>

                          {/* Mailing Address No */}
                          <div className="col-md-6">
                            <div className="form-group" id="id_mailingAddressNo">
                              <label>Mailing Address No*</label>
                              <div className="form-controls">
                                <Input
                                  type="number"
                                  className="form-control no-icon"
                                  name="mailingAddressNo"
                                  value={formData.mailingAddressNo}
                                  onChange={handleChange}
                                />
                              </div>
                              {errors.mailingAddressNo && (
                                <span className="error_meesage">{errors.mailingAddressNo}</span>
                              )}

                            </div>
                          </div>

                          {/* Mailing Address Line 1 */}
                          <div className="col-md-6">
                            <div className="form-group" id="id_mailingAddressLine1">
                              <label>Mailing Address Line 1*</label>
                              <div className="form-controls">
                                <Input
                                  type="text"
                                  className="form-control no-icon"
                                  name="mailingAddressLine1"
                                  value={formData.mailingAddressLine1}
                                  onChange={handleChange}
                                />
                              </div>
                              {errors.mailingAddressLine1 && (
                                <span className="error_meesage">{errors.mailingAddressLine1}</span>
                              )}
                            </div>
                          </div>

                          {/* Mailing City */}
                          <div className="col-md-6">
                            <div className="form-group" id="id_mailingCity">
                              <label>Mailing City*</label>
                              <div className="form-controls">
                                <Input
                                  type="text"
                                  className="form-control no-icon"
                                  name="mailingCity"
                                  value={formData.mailingCity}
                                  onChange={handleChange}
                                />
                              </div>
                              {errors.mailingCity && (
                                <span className="error_meesage">{errors.mailingCity}</span>
                              )}
                            </div>
                          </div>

                          {/* Mailing State Code */}
                          <div className="col-md-6">
                            <div className="form-group" id="id_mailingStateCode">
                              <label>Mailing State Code*</label>
                              <div className="form-controls">
                                <Input
                                  type="text"
                                  className="form-control no-icon"
                                  name="mailingStateCode"
                                  value={formData.mailingStateCode}
                                  onChange={handleChange}
                                  maxLength="2"
                                />
                              </div>
                              {errors.mailingStateCode && (
                                <span className="error_meesage">{errors.mailingStateCode}</span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group" id="id_deliverycountry">
                              <label>Mailing Country*</label>
                              <div className="form-controls">
                                <ReactFlagsSelect
                                  className="form-control no-icon"
                                  selected={formData.mailingCountryCode}
                                  onSelect={(code) => {
                                    handleChange({ target: { name: 'mailingCountryCode', value: code } });
                                  }}
                                  searchable={true}
                                />

                              </div>
                              {errors.mailingCountryCode && (
                                <span className="error_meesage">{errors.mailingCountryCode}</span>
                              )}
                            </div>
                          </div>
                          {/* Nationality */}
                          <div className="col-md-6">
                            <div className="form-group" id="id_nationality">
                              <label>Nationality*</label>
                              <div className="form-controls">
                                <Input
                                  type="text"
                                  className="form-control no-icon"
                                  name="nationality"
                                  value={formData.nationality}
                                  onChange={handleChange}
                                  maxLength="2"
                                />
                              </div>
                              {errors.nationality && (
                                <span className="error_meesage">{errors.nationality}</span>
                              )}
                            </div>
                          </div>

                          {/* Mailing Postal Code */}
                          <div className="col-md-6">
                            <div className="form-group" id="id_mailingPostalCode">
                              <label>Mailing Postal Code*</label>
                              <div className="form-controls">
                                <Input
                                  type="text"
                                  className="form-control no-icon"
                                  name="mailingPostalCode"
                                  value={formData.mailingPostalCode}
                                  onChange={handleChange}
                                  maxLength="10"
                                />
                              </div>
                              {errors.mailingPostalCode && (
                                <span className="error_meesage">{errors.mailingPostalCode}</span>
                              )}
                            </div>
                          </div>

                          {/* Tax Reference Number */}
                          <div className="col-md-6">
                            <div className="form-group" id="id_taxReferenceNumber">
                              <label>Tax Reference Number*</label>
                              <div className="form-controls">
                                <Input
                                  type="number"
                                  className="form-control no-icon"
                                  name="taxReferenceNumber"
                                  value={formData.taxReferenceNumber}
                                  onChange={handleChange}
                                />
                              </div>
                              {errors.taxReferenceNumber && (
                                <span className="error_meesage">{errors.taxReferenceNumber}</span>
                              )}
                            </div>
                          </div>

                          {/* Gender */}
                          <div className="col-md-6">
                            <div className="form-group" id="id_gender">
                              <label>Gender*</label>
                              <div className="form-controls">
                                <select
                                  className="form-control no-icon"
                                  name="gender"
                                  value={formData.gender}
                                  onChange={handleChange}
                                >
                                  <option value="">Select</option>
                                  <option value="male">Male</option>
                                  <option value="female">Female</option>
                                  <option value="other">Other</option>
                                </select>
                              </div>
                              {errors.gender && (
                                <span className="error_meesage">{errors.gender}</span>
                              )}
                            </div>
                          </div>

                          {/* Date of Birth */}
                          <div className="col-md-6">
                            <div className="form-group" id="id_dateOfBirth">
                              <label>Date of Birth*</label>
                              <div className="form-controls">
                                <Input
                                  type="date"
                                  className="form-control no-icon"
                                  name="dateOfBirth"
                                  value={formData.dateOfBirth}
                                  onChange={handleChange}
                                />
                              </div>
                              {errors.dateOfBirth && (
                                <span className="error_meesage">{errors.dateOfBirth}</span>
                              )}

                            </div>
                          </div>

                          {/* US Residency Status */}
                          <div className="col-md-6">
                            <div className="form-group" id="id_usResidencyStatus">
                              <label>US Residency Status*</label>
                              <div className="form-controls">
                                <select
                                  className="form-control no-icon"
                                  name="usResidencyStatus"
                                  value={formData.usResidencyStatus}
                                  onChange={handleChange}
                                >
                                  <option value="">Select</option>
                                  <option value="US_CITIZEN">US Citizen</option>
                                  <option value="RESIDENT_ALIEN">Resident Alien</option>
                                  <option value="NON_RESIDENT_ALIEN">Non-Resident Alien</option>
                                </select>
                              </div>
                              {errors.usResidencyStatus && (
                                <span className="error_meesage">{errors.usResidencyStatus}</span>
                              )}
                            </div>
                          </div>

                          {/* Employment Status */}
                          <div className="col-md-6">
                            <div className="form-group" id="id_employmentStatus">
                              <label>Employment Status*</label>
                              <div className="form-controls">
                                <select
                                  className="form-control no-icon"
                                  name="employmentStatus"
                                  value={formData.employmentStatus}
                                  onChange={handleChange}
                                >
                                  <option value="">Select</option>
                                  <option value="EMPLOYEE">Employee</option>
                                  <option value="SELF_EMPLOYED">Self-Employed</option>
                                  <option value="RETIRED">Retired</option>
                                  <option value="UNEMPLOYED">Unemployed</option>
                                  <option value="OTHER">Other</option>
                                </select>
                              </div>
                              {errors.employmentStatus && (
                                <span className="error_meesage">{errors.employmentStatus}</span>
                              )}
                            </div>
                          </div>

                          {/* Employer Name */}
                          <div className="col-md-6">
                            <div className="form-group" id="id_employerName">
                              <label>Employer Name*</label>
                              <div className="form-controls">
                                <Input
                                  type="text"
                                  className="form-control no-icon"
                                  name="employerName"
                                  value={formData.employerName}
                                  onChange={handleChange}
                                />
                              </div>
                              {errors.employerName && (
                                <span className="error_meesage">{errors.employerName}</span>
                              )}

                            </div>
                          </div>

                          {/* Occupation */}
                          <div className="col-md-6">
                            <div className="form-group" id="id_occupation">
                              <label>Occupation*</label>
                              <div className="form-controls">
                                <Input
                                  type="text"
                                  className="form-control no-icon"
                                  name="occupation"
                                  value={formData.occupation}
                                  onChange={handleChange}
                                />
                              </div>
                              {errors.occupation && (
                                <span className="error_meesage">{errors.occupation}</span>
                              )}
                            </div>
                          </div>

                          {/* Primary Source of Funds */}
                          <div className="col-md-6">
                            <div className="form-group" id="id_primarySourceOfFunds">
                              <label>Primary Source of Funds*</label>
                              <div className="form-controls">
                                <select
                                  className="form-control no-icon"
                                  name="primarySourceOfFunds"
                                  value={formData.primarySourceOfFunds}
                                  onChange={handleChange}
                                >
                                  <option value="">Select</option>
                                  <option value="EMPLOYMENT">Employment</option>
                                  <option value="SAVINGS">Savings</option>
                                  <option value="WINNINGS">Winnings</option>
                                  <option value="MARITAL">Marital</option>
                                  <option value="REAL_ESTATE">Real Estate</option>
                                  <option value="TRUST">Trust</option>
                                  <option value="INVESTMENT">Investment</option>
                                  <option value="OTHER">Other</option>
                                </select>
                              </div>
                              {errors.primarySourceOfFunds && (
                                <span className="error_meesage">{errors.primarySourceOfFunds}</span>
                              )}

                            </div>
                          </div>

                          {/* Engage in Activities */}
                          <div className="col-md-6">
                            <div className="form-group" id="id_engageInActivities">
                              <label>Engage in Activities*</label>
                              <div className="form-controls">
                                <select
                                  className="form-control no-icon"
                                  name="engageInActivities"
                                  value={formData.engageInActivities}
                                  onChange={handleChange}
                                >
                                  <option value="">Select</option>
                                  <option value="NONE">None</option>
                                  {/* <option value="ADULT_ENTERTAINMENT">Adult Entertainment</option>
                                  <option value="DRUGS">Drugs</option>
                                  <option value="FIREARMS">Firearms</option>
                                  <option value="GAMBLING">Gambling</option>
                                  <option value="MARIJUANA">Marijuana</option>
                                  <option value="TUMBLING">Tumbling</option> */}
                                </select>
                              </div>
                              {errors.engageInActivities && (
                                <span className="error_meesage">{errors.engageInActivities}</span>
                              )}
                            </div>
                          </div>

                          {/* Vendors and Counterparties */}

                          <div className="col-md-6">
                            <div className="form-group" id="id_vendorsAndCounterparties">
                              <label>Vendors and Counterparties*</label>
                              <div className="form-controls">
                                {/* Searchable input with selected values */}
                                <div className="selected-tags">
                                  {formData.vendorsAndCounterparties.map((code) => (
                                    <span key={code} className="selected-tag">
                                      {code}{" "}
                                      <button
                                        type="button"
                                        className="cross-icon"
                                        onClick={() => {
                                          setFormData((prev) => ({
                                            ...prev,
                                            vendorsAndCounterparties: prev.vendorsAndCounterparties.filter(
                                              (c) => c !== code
                                            ),
                                          }));
                                        }}
                                      >
                                        ✕
                                      </button>
                                    </span>
                                  ))}
                                  <input
                                    type="text"
                                    className="form-control no-icon d-none"
                                    placeholder="Select jurisdictions"
                                    readOnly
                                  />
                                </div>

                                {/* Multi-select dropdown */}
                                <select
                                  className="form-control no-icon mt-2"
                                  onChange={(e) => {
                                    const selectedValue = e.target.value;
                                    if (
                                      selectedValue &&
                                      !formData.vendorsAndCounterparties.includes(selectedValue)
                                    ) {
                                      setFormData((prev) => ({
                                        ...prev,
                                        vendorsAndCounterparties: [
                                          ...prev.vendorsAndCounterparties,
                                          selectedValue,
                                        ],
                                      }));
                                    }
                                  }}
                                >
                                  <option value="">Select</option>
                                  <option value="SELF">Self</option>
                                  <option value="MERCHANTS_SUPPLIERS">Merchants & Suppliers</option>
                                  <option value="CUSTOMERS">Customers</option>
                                  <option value="EMPLOYEES">Employees</option>
                                  <option value="CONTRACTORS">Contractors</option>
                                  <option value="FRIENDS">Friends</option>
                                  <option value="FAMILY">Family</option>
                                </select>
                              </div>

                              {errors.vendorsAndCounterparties && (
                                <span className="error_meesage">{errors.vendorsAndCounterparties}</span>
                              )}
                            </div>
                          </div>


                          {/* Funds Send/Receive Jurisdictions */}
                          <div className="col-md-6">
                            <div className="form-group" id="id_fundsSendReceiveJurisdictions">
                              <label>Funds Send/Receive Jurisdictions*</label>
                              <div className="form-controls">
                                {/* Searchable input with selected values */}
                                <div className="selected-tags">
                                  {formData.fundsSendReceiveJurisdictions.map((code) => (
                                    <span key={code} className="selected-tag">
                                      {code}{" "}
                                      <button
                                        type="button"
                                        className="cross-icon"
                                        onClick={() => {
                                          setFormData((prev) => ({
                                            ...prev,
                                            fundsSendReceiveJurisdictions: prev.fundsSendReceiveJurisdictions.filter(
                                              (c) => c !== code
                                            ),
                                          }));
                                        }}
                                      >
                                        ✕
                                      </button>
                                    </span>
                                  ))}
                                  <input
                                    type="text"
                                    className="form-control no-icon d-none"
                                    placeholder="Select jurisdictions"
                                    readOnly
                                  />
                                </div>

                                {/* Multi-select dropdown */}
                                <select
                                  className="form-control no-icon mt-2"
                                  onChange={(e) => {
                                    const selectedValue = e.target.value;
                                    if (
                                      selectedValue &&
                                      !formData.fundsSendReceiveJurisdictions.includes(selectedValue)
                                    ) {
                                      setFormData((prev) => ({
                                        ...prev,
                                        fundsSendReceiveJurisdictions: [
                                          ...prev.fundsSendReceiveJurisdictions,
                                          selectedValue,
                                        ],
                                      }));
                                    }
                                  }}
                                >
                                  <option value="">Select country...</option>
                                  <option value="US">United States (US)</option>
                                  <option value="CA">Canada (CA)</option>
                                  <option value="GB">United Kingdom (GB)</option>
                                  <option value="AU">Australia (AU)</option>
                                  <option value="DE">Germany (DE)</option>
                                  <option value="FR">France (FR)</option>
                                  <option value="IN">India (IN)</option>
                                  <option value="JP">Japan (JP)</option>
                                  <option value="CN">China (CN)</option>
                                </select>
                              </div>

                              {errors.fundsSendReceiveJurisdictions && (
                                <span className="error_meesage">{errors.fundsSendReceiveJurisdictions}</span>
                              )}
                            </div>
                          </div>

                          {/* Total Assets */}
                          <div className="col-md-6">
                            <div className="form-group" id="id_totalAssets">
                              <label>Total Assets*</label>
                              <div className="form-controls">
                                <select
                                  className="form-control no-icon"
                                  name="totalAssets"
                                  value={formData.totalAssets}
                                  onChange={handleChange}
                                >
                                  <option value="">Select</option>
                                  <option value="UPTO_10K">0 to 10,000</option>
                                  <option value="TEN_TO_100K">10,000 to 100,000</option>
                                  <option value="ONEHUNDREDK_TO_1M">100,000 to 1,000,000</option>
                                  <option value="ONE_TO_10M">1,000,000 to 10,000,000</option>
                                  <option value="TEN_TO_50M">10,000,000 to 50,000,000</option>
                                  <option value="FIFTY_TO_250M">50,000,000 to 250,000,000</option>
                                  <option value="MORE_THAN_250M">250,000,000+</option>
                                </select>
                              </div>
                              {errors.totalAssets && (
                                <span className="error_meesage">{errors.totalAssets}</span>
                              )}
                            </div>
                          </div>

                          {/* USD Value of Fiat */}
                          <div className="col-md-6">
                            <div className="form-group" id="id_usdValueOfFiat">
                              <label>USD Value of Fiat*</label>
                              <div className="form-controls">
                                <select
                                  className="form-control no-icon"
                                  name="usdValueOfFiat"
                                  value={formData.usdValueOfFiat}
                                  onChange={handleChange}
                                >
                                  <option value="">Select</option>
                                  <option value="UPTO_10K">0 to 10,000</option>
                                  <option value="TEN_TO_100K">10,000 to 100,000</option>
                                  <option value="ONEHUNDREDK_TO_1M">100,000 to 1,000,000</option>
                                  <option value="ONE_TO_10M">1,000,000 to 10,000,000</option>
                                  <option value="TEN_TO_50M">10,000,000 to 50,000,000</option>
                                  <option value="FIFTY_TO_250M">50,000,000 to 250,000,000</option>
                                  <option value="MORE_THAN_250M">250,000,000+</option>
                                </select>
                              </div>
                              {errors.usdValueOfFiat && (
                                <span className="error_meesage">{errors.usdValueOfFiat}</span>
                              )}
                            </div>
                          </div>

                          {/* Monthly Deposits */}
                          <div className="col-md-6">
                            <div className="form-group" id="id_monthlyDeposits">
                              <label>Monthly Deposits*</label>
                              <div className="form-controls">
                                <select
                                  className="form-control no-icon"
                                  name="monthlyDeposits"
                                  value={formData.monthlyDeposits}
                                  onChange={handleChange}
                                >
                                  <option value="">Select</option>
                                  <option value="UPTO_5">0 to 5000</option>
                                  <option value="FIVE_TO_10">5000 to 10000</option>
                                  <option value="TEN_TO_25">10000 to 25000</option>
                                  <option value="MORE_THAN_25">25000+</option>
                                </select>
                              </div>
                              {errors.monthlyDeposits && (
                                <span className="error_meesage">{errors.monthlyDeposits}</span>
                              )}
                            </div>
                          </div>

                          {/* Monthly Withdrawals */}
                          <div className="col-md-6">
                            <div className="form-group" id="id_monthlyWithdrawals">
                              <label>Monthly Withdrawals*</label>
                              <div className="form-controls no-icon">
                                <select
                                  className="form-control"
                                  name="monthlyWithdrawals"
                                  value={formData.monthlyWithdrawals}
                                  onChange={handleChange}
                                >
                                  <option value="">Select</option>
                                  <option value="UPTO_5">0 to 5000</option>
                                  <option value="FIVE_TO_10">5000 to 10000</option>
                                  <option value="TEN_TO_25">10000 to 25000</option>
                                  <option value="MORE_THAN_25">25000+</option>
                                </select>
                              </div>
                              {errors.monthlyWithdrawals && (
                                <span className="error_meesage">{errors.monthlyWithdrawals}</span>
                              )}
                            </div>
                          </div>

                          {/* Monthly Investment Deposit */}
                          <div className="col-md-6">
                            <div className="form-group" id="id_monthlyInvestmentDeposit">
                              <label>Monthly Investment Deposit*</label>
                              <div className="form-controls">
                                <select
                                  className="form-control no-icon"
                                  name="monthlyInvestmentDeposit"
                                  value={formData.monthlyInvestmentDeposit}
                                  onChange={handleChange}
                                >
                                  <option value="">Select</option>
                                  <option value="UPTO_1K">0 to 1,000</option>
                                  <option value="ONE_TO_100K">1,000 to 100,000</option>
                                  <option value="ONEHUNDREDK_TO_1M">100,000 to 1,000,000</option>
                                  <option value="MILLION_TO_5M">1,000,000 to 5,000,000</option>
                                  <option value="MORE_THAN_5M">5,000,000+</option>
                                </select>
                              </div>
                              {errors.monthlyInvestmentDeposit && (
                                <span className="error_meesage">{errors.monthlyInvestmentDeposit}</span>
                              )}
                            </div>
                          </div>

                          {/* Monthly Investment Withdrawal */}
                          <div className="col-md-6">
                            <div className="form-group" id="id_monthlyInvestmentWithdrawal">
                              <label>Monthly Investment Withdrawal*</label>
                              <div className="form-controls">
                                <select
                                  className="form-control no-icon"
                                  name="monthlyInvestmentWithdrawal"
                                  value={formData.monthlyInvestmentWithdrawal}
                                  onChange={handleChange}
                                >
                                  <option value="">Select</option>
                                  <option value="UPTO_1K">0 to 1,000</option>
                                  <option value="ONE_TO_100K">1,000 to 100,000</option>
                                  <option value="ONEHUNDREDK_TO_1M">100,000 to 1,000,000</option>
                                  <option value="MILLION_TO_5M">1,000,000 to 5,000,000</option>
                                  <option value="MORE_THAN_5M">5,000,000+</option>
                                </select>
                              </div>
                              {errors.monthlyInvestmentWithdrawal && (
                                <span className="error_meesage">{errors.monthlyInvestmentWithdrawal}</span>
                              )}
                            </div>
                          </div>

                          {/* Passport Number */}
                          <div className="col-md-6">
                            <div className="form-group" id="id_passportNumber">
                              <label>Passport Number*</label>
                              <div className="form-controls">
                                <Input
                                  type="text"
                                  className="form-control no-icon"
                                  name="passportNumber"
                                  value={formData.passportNumber}
                                  onChange={handleChange}
                                />
                              </div>
                              {errors.passportNumber && (
                                <span className="error_meesage">{errors.passportNumber}</span>
                              )}
                            </div>
                          </div>

                          {/* Phone */}
                          <div className="col-md-6">
                            <div className="form-group" id="id_phone">
                              <label>Phone*</label>
                              <div className="form-controls">
                                <Input
                                  type="tel"
                                  className="form-control no-icon"
                                  name="phone"
                                  value={formData.phone}
                                  onChange={handleChange}
                                />
                              </div>
                              {errors.phone && (
                                <span className="error_meesage">{errors.phone}</span>
                              )}
                            </div>
                          </div>

                          {/* Email */}
                          <div className="col-md-6">
                            <div className="form-group" id="id_email">
                              <label>Email*</label>
                              <div className="form-controls">
                                <Input
                                  type="email"
                                  className="form-control no-icon"
                                  name="email"
                                  value={formData.email}
                                  onChange={handleChange}
                                />
                              </div>
                              {errors.email && (
                                <span className="error_meesage">{errors.email}</span>
                              )}
                            </div>
                          </div>

                          {/* Terms and Conditions */}
                          <div className="col-md-12">
                            <div className="form-group">
                              <div className="form-controls">
                                <label>
                                  <input
                                    type="checkbox"
                                    checked={formData.termsAndConditions}
                                    onChange={(e) =>
                                      setFormData((prev) => ({
                                        ...prev,
                                        termsAndConditions: e.target.checked,
                                      }))
                                    }
                                  />
                                  I agree to the terms and conditions.
                                </label>
                              </div>
                              {errors.termsAndConditions && (
                                <span className="error_meesage">{errors.termsAndConditions}</span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group submit-field d-flex justify-content-end mt-20 mb-0">
                              <a
                                className="btn btn-outline mr-19"
                                data-bs-dismiss="modal"
                                onClick={props.onHide}
                              >
                                Cancel
                              </a>
                              <a className="btn btn-large" onClick={IssueCardHolderHandler}>
                                Create Account
                              </a>
                            </div>
                          </div>
                        </div>
                        <CheckButton
                          style={{ display: "none" }}
                          ref={checkBtn}
                        />
                      </Form>
                    </div>
                  </div>
                  <div
                    className={cardContentClassName}
                    id="cardDetails"
                    role="tabpanel"
                    aria-labelledby="cardDetails-tab"
                  >
                    <div className="row row-card-details-popup">
                      <div className="col-md-12">
                        <ul className="ul-card-details-popup ul-issue-card">
                          <li className="mb-20">
                            <div className="row">
                              <div className="col-4">
                                <label>Company Name</label>
                              </div>
                              <div className="col-8">
                                <span>{FirstName}</span>
                              </div>
                            </div>
                          </li>

                          <li className="mb-20">
                            <div className="row">
                              <div className="col-4">
                                <label>Available Balance</label>
                              </div>
                              <div className="col-8">
                                <span>$ 0</span>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group submit-field d-flex justify-content-end mt-20 mb-0">
                          <button
                            className="btn btn-large"
                            data-bs-dismiss="modal"
                            onClick={IssueCardDoneHandler}
                            disabled={disableBtns}
                          >
                            Done
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DialoguePopup
        show={modalShow}
        onHide={() => setModalShow(false)}
        modelheading={modelhdng}
        modelbody={modelbdy}
        modelicon={MdlIcon}
        hideallpopups={props.onHide}
      />
    </Fragment>
  );
};
export default AddBankAccountsBody;
