import React, { useState, useEffect, useRef, Fragment, useCallback } from "react";
import commonService from "../../services/common.service";
import validator from "validator";
import { history } from "../../helpers/history";
import DateFormat from "../../helpers/DateFormat";
import crossImg from "../../assets/images/icons/cross.svg";
import SearchGridInputHelper from "../../helpers/SearchGridInputHelper";
import SearchGridTableHelper from "../../helpers/SearchGridTableHelper";
import PendingCardHolders from "../../components/cardholders/PendingCardHolders";
import RefreshToken from "../../helpers/RefreshToken";
const SearchGridInputData = [
  {
    Name: "",
    TokenId: "",
    IssueDate: "",
    ExpiryDate: "",
    DateFrom: "",
    DateTo: "",
    CardNumber: "",
    startIndex: 1,
    scrolled: false,
    isFirstTime: true,
    SearchClearClicked: false,
    ClientId: "",
    IssuerType: "",
  },
];



const SearchGridAccounts = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const [searchData, setSearchData] = useState();
  const [searchGridSuccess, setSearchGridSuccess] = useState(false);
  const [name, setCompanyName] = useState("");
  const [tokenId, setTokentId] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [dateIssued, setDateIssued] = useState();
  const [dateExpiry, setDateExpiry] = useState();
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const searchTbl = useRef();
  const [loadingData, setLoadingData] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [SearchInputDisabled, setSearchDisabled] = useState(false);
  const [clientIdVal, setClientIdVal] = useState("");
  const [IssuerCategory, setIssuerCategory] = useState("");
  const [AccountTitle, setAccountTitle] = useState("");
  const [AccountNumber, setAccountNumber] = useState("");
  const observer = useRef();
  const lastElementRef = useCallback(node => {
    if (loadingData) return
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        tblScrollHandler();
      }
    })
    if (node) observer.current.observe(node)
  }, [loadingData, searchData])


  const nameChangeHandler = (e) => {
    SearchGridInputData[0].Name = e.target.value;
    setCompanyName(SearchGridInputData[0].Name);
  };

  const cardNumberChangeHandler = (e) => {
    SearchGridInputData[0].CardNumber = e.target.value;
    setCardNumber(SearchGridInputData[0].CardNumber);
  };
  const onChangeMswipeIssuer = e => {
    const name = e.target.selectedOptions[0].value;
    SearchGridInputData[0].IssuerType = name;
    setIssuerCategory(name);
    props?.IssuerCategory(name);

  };

  const tokenChangeHandler = (e) => {
    SearchGridInputData[0].TokenId = e.target.value;
    let data = SearchGridInputData[0].TokenId.split(" ").join("")
    if (validator.isNumeric(data))
      setTokentId(data);
    else setTokentId("");
  };

  const dateIssueHandler = (e) => {
    SearchGridInputData[0].IssueDate = e;
    if (e)
      SearchGridInputData[0].IssueDate = dateFilter(
        SearchGridInputData[0].IssueDate
      );
    setDateIssued(SearchGridInputData[0].IssueDate);
  };
  const dateExpiryHandler = (e) => {
    SearchGridInputData[0].ExpiryDate = e;
    if (e)
      SearchGridInputData[0].ExpiryDate = dateFilter(
        SearchGridInputData[0].ExpiryDate
      );
    setDateExpiry(SearchGridInputData[0].ExpiryDate);
  };
  const dateFromHandler = (e) => {
    SearchGridInputData[0].DateFrom = e;
    if (e) {
      SearchGridInputData[0].DateFrom = dateFilter(
        SearchGridInputData[0].DateFrom
      );
    }

    setDateFrom(SearchGridInputData[0].DateFrom);
  };
  const dateToHandler = (e) => {
    SearchGridInputData[0].DateTo = e;
    if (e)
      SearchGridInputData[0].DateTo = dateFilter(SearchGridInputData[0].DateTo);
    setDateTo(SearchGridInputData[0].DateTo);
  };
  const dateFilter = (date) => {
    return new Date(date.getTime());
  };
  const handleInputSearchClick = (e) => {
    if (e)
      e.preventDefault();
    setModalShow(true);
    setSearchDisabled(true);
  };
  const ClientNameChangeHandler = (e) => {
    const clientName = e.target.selectedOptions[0].value;
    setClientIdVal(clientName);
  };


  const SearchClickHandler = (e, isFromUseEffect) => {
    if (e) {
      e.preventDefault();
      SearchGridInputData[0].startIndex = 1;
    }
    if (isFromUseEffect) {
      SearchGridInputData[0].startIndex = 1;
      handleInputSearchClick();
    }


    if (props.clientId || clientIdVal !== "")
      SearchGridInputData[0].ClientId = !props.clientId
        ? clientIdVal
        : props.clientId;
    else SearchGridInputData[0].ClientId = "";
    setLoadingData(true);
    HideSearchPopupDiv();
    if (!SearchGridInputData[0].scrolled) {
      setSearchGridSuccess(false);
    }
    commonService
      .searhGridAccountIbans(
        AccountTitle,
        AccountNumber,
        name,
        tokenId,
        cardNumber,
        IssuerCategory,
        dateIssued || "0001-01-01",
        dateExpiry || "0001-01-01",
        dateFrom || "0001-01-01",
        dateTo || "0001-01-01",
        SearchGridInputData[0].startIndex,
        props.methodname,
        props.apiurl,
        SearchGridInputData[0].ClientId.toString(),
        props.screenname === "newcards" ? "Pending" : props.screenname === "deposit" ? props.status
          : "true"
      )
      .then((resp) => {

        setLoadingData(false);
        setClientIdVal("");
        if (resp.data.success) {
          setModalShow(false);
        }
        if (resp?.data?.payload?.item1?.pageModel?.length > 0 || searchData.length > 0) {
          setTotalCount(resp?.data?.payload?.item1?.pageModel?.totalCount);
          if (SearchGridInputData[0].scrolled) {

            let appendedData = searchData.concat(resp?.data?.payload?.item1?.pageModel);
            setSearchData(appendedData);
            props?.setDataset && props?.setDataset(appendedData);
          } else {
            setSearchData(resp?.data?.payload?.item1?.pageModel);
            if (props?.setDataset)
              props.setDataset(resp?.data?.payload?.item1?.pageModel);

            SearchGridInputData[0].startIndex = 1;
          }
          setSearchGridSuccess(true);

          SearchGridInputData[0].scrolled = false;
          searchTbl.current.onscroll = tblScrollHandler;
          SearchGridInputData[0].isFirstTime = false;
          // }
        } else {
          SearchGridInputData[0].startIndex = 1;
        }
      })
      .catch((error) => {
        setSearchGridSuccess(false);
        setLoadingData(false);
        (async () => {
          let resp = await RefreshToken(
            error && error.response && error.response.status
          );
          if (resp) {
            SearchClickHandler();
          }
        })();
      });
  };


  useEffect(() => {
    SearchClickHandler(null, true);
  }, []);
  const showAccountHolderDetailPage = (id) => {
    history.push("/bankaccountholderdetails", { id });
  };

  const tblScrollHandler = (e) => {
    if (
      !SearchGridInputData[0].scrolled &&
      SearchGridInputData[0].startIndex + 10 <= totalCount
    ) {
      SearchGridInputData[0].scrolled = true;
      SearchGridInputData[0].startIndex =
        SearchGridInputData[0].startIndex + 10;
      SearchClickHandler();
    }
  };
  const ClearSearchData = () => {
    setCompanyName("");
    setCardNumber("");
    setTokentId("");
    setIssuerCategory("");
    ClearInputData();
  };
  const ClearInputData = () => {
    dateFromHandler("");
    dateToHandler("");
    dateExpiryHandler("");
    dateIssueHandler("");
    if (document.getElementById("clientNameDD") !== null)
      document.getElementById("clientNameDD").value = "";
    setClientIdVal("");
    setTimeout(() => {
      [...document.getElementsByClassName("r-input-group")].forEach(
        (element) => {
          element.children[0].value = "";
        }
      );
      document.getElementById("searchBtn").click();
    }, 500);
  };
  const renderTableHeader = () => {
    let header = Object.values(props.headersdata);
    return header.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  };

  const renderTableData = () => {
    return searchData.map((data, index) => {
      if (
        props.screenname === "bankaccount"
      ) {
        const {
          accountId,
          accountNumberAch,
          accountNumberFed,
          applicationId,
          clientId,
          clientName,
          firstName,
          lastName,
          routingNumberAch,
          routingNumberFed,
          tokenId,
          userId
        } = data; //destructuring

        if (searchData.length === index + 1) {
          return (
            <tr key={userId} ref={lastElementRef}>
             
              <td style={{ textTransform: "capitalize" }} title={firstName}>{firstName?.substr(0, 20) ?? "-"}{firstName?.length > 20 && ("...")}</td>
              <td style={{ textTransform: "capitalize" }} title={clientName}>{clientName?.substr(0, 20) ?? "-"}{clientName?.length > 20 && ("...")}</td>
              <td>{tokenId}</td>
              <td>{accountId}</td>
              
              <td>
                <span
                  title="View Card Holder Details"
                  className="icon-show"
                  onClick={() => showAccountHolderDetailPage(userId)}
                ></span>

              </td>
            </tr>
          );
        }
        else {
          return (
            <tr key={userId} ref={lastElementRef}>
             
              <td style={{ textTransform: "capitalize" }} title={firstName}>{firstName?.substr(0, 20) ?? "-"}{firstName?.length > 20 && ("...")}</td>

              <td style={{ textTransform: "capitalize" }} title={firstName}>{firstName?.substr(0, 20) ?? "-"}{firstName?.length > 20 && ("...")}</td>
              <td>{tokenId}</td>
              <td>{tokenId}</td>
             
              <td>
                <span
                  title="View Card Holder Details"
                  className="icon-show"
                  onClick={() => showAccountHolderDetailPage(userId)}
                ></span>

              </td>
            </tr>
          );
        }
      }
    });
  };

  const HideSearchPopupDiv = () => {
    setModalShow(false);
    setSearchDisabled(false);
  };
  return (
    <Fragment>
      <SearchGridInputHelper
        placeholdervalue={props.placeholdervalue}
        handleInputSearchClick={handleInputSearchClick}
        searchinputdisabled={SearchInputDisabled}
        modalshow={modalShow}
        crossimg={crossImg}
        hidesearchpopupdiv={HideSearchPopupDiv}
        name={name}
        namechangehandler={nameChangeHandler}
        screenname={props.screenname}
        tokenid={tokenId}
        tokenchangehandler={tokenChangeHandler}
        cardNumber={cardNumber}
        cardchangehandler={cardNumberChangeHandler}
        onChangeMswipeIssuer={onChangeMswipeIssuer}
        IssuerCategory={IssuerCategory}
        dateissued={dateIssued}
        dateissuehandler={dateIssueHandler}
        dateexpiry={dateExpiry}
        dateexpiryhandler={dateExpiryHandler}
        datefrom={dateFrom}
        datefromhandler={dateFromHandler}
        dateto={dateTo}
        datetohandler={dateToHandler}
        clearsearchdata={ClearSearchData}
        searchclickhandler={SearchClickHandler}
        clientnamechangehandler={ClientNameChangeHandler}
      />
      {props.screenname !== "deposit" && props.screenname !== "newcards" && (
        <SearchGridTableHelper
          searchgridsuccess={searchGridSuccess}
          searchtbl={searchTbl}
          tblscrollhandler={tblScrollHandler}
          rendertableheader={renderTableHeader}
          renderTableData={renderTableData}
          loadingdata={loadingData}
          totalcount={totalCount}
          screenname={props.screenname}
        />
      )}
      
      {props.screenname === "newcards" && (
        <PendingCardHolders
          searchdata={searchData}
          searchclickhandler={SearchClickHandler}
          searchgridsuccess={searchGridSuccess}
          searchtbl={searchTbl}
          tblscrollhandler={tblScrollHandler}
          rendertableheader={renderTableHeader}
          renderTableData={renderTableData}
          loadingdata={loadingData}
          totalcount={totalCount}
        />
      )}
    </Fragment>
  );
};

export default SearchGridAccounts;
